import { createRouter, createWebHistory } from 'vue-router'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/pages/viewpage.action',
    name: 'ViewPage',
    component: () => import(/* webpackChunkName: "ViewPage" */ '../views/ViewPage.vue')
  },
  {
    path: '/display/docs/:pageId',
    name: 'docs',
    component: () => import(/* webpackChunkName: "ViewPage" */ '../views/DisplayDocs.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
