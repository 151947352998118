<template>
  <div id="header" class="grid">
    <img class="recopick-logo" src="@/assets/images/atl_site.png" alt="recopick"
                                    @click="this.$router.push({path: '/'});">
    <div class="update-date"><span style="color: rgb(230,230,230);">최종수정 : 2024-11-12</span></div>
  </div>
  <div id="container">
    <div id="sidebar-container">
      <Sidebar></Sidebar>
      <div id="handle"></div>
    </div>
    <div id="contants-container">
      <router-view></router-view>
    </div>
  </div>
  <div id="footer"></div>
</template>

<script>
import Sidebar from "@/components/Sidebar"
import '@/assets/css/batch.css'
import '@/assets/css/batch1.css'
import '@/assets/css/batch2.css'

export default {
  name: 'App',
  components: {
    Sidebar,
  },
  created() {
    let isResizing = false;
    let sideBarTransition = null;
    let mainContainerTransition = null;
    let handleCursor = null;
    let defaultSidebarWidth = 30;

    document.addEventListener('DOMContentLoaded', () => {
      const sidebar = document.querySelector('#sidebar-container');
      const mainContainer = document.querySelector('#contants-container');
      const handle = document.querySelector('#sidebar-container #handle');

      handle.addEventListener('mousedown', (e) => {
        e.preventDefault();
        isResizing = true;
        sideBarTransition = sidebar.style.transition;
        mainContainerTransition = mainContainer.style.transition;
        handleCursor = e.target.style.cursor;
        document.body.classList.add('notransition');
      })

      document.addEventListener('mousemove', (e) => {
        if (!isResizing) {
          return
        }

        e.preventDefault();

        let newX = e.clientX + 10;

        if (newX < defaultSidebarWidth) {
          return
        }

        e.target.style.cursor = 'auto';
        sidebar.style.transition = 'none';
        mainContainer.style.transition = 'none';
        sidebar.style.width = newX + 'px';
        mainContainer.style.marginLeft = newX + 'px'
      })

      document.addEventListener('mouseup', () => {
        isResizing = false;
        sidebar.style.transition = sideBarTransition;
        mainContainer.style.transition = mainContainerTransition;
        handle.style.cursor = handleCursor;
        document.body.classList.remove('notransition')
      })
    })
  }

}
</script>

<style>
#app {
}

body {
  background-color: white;
}

#header {
  position: fixed;
  top: 0;
  right: 0;
  background-color: #0049B0;
  width: 100%;
  height: 40px;
  z-index: 2147483647;
}

#header img {
  height: 60%;
  padding-top: 5px;
  padding-left: 1%;
  cursor: pointer;
}

#container {
  position: relative;
}

#sidebar-container {
  font-size: 14px;
  width: 380px;
  position: fixed;
  transition: all .3s ease-in-out;
  z-index: 999;
  overflow: hidden;
  overflow-y: scroll;
  height: 100%;
  bottom: 0;
  top: 0;
  display: block;
  background-color: #f4f5f7;
}

#sidebar-container #handle {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 16px;
  cursor: col-resize;
  right: 0;
  left: auto;
}

#contants-container {
  padding-top: 4rem;
  background-color: white;
  padding-bottom: 4rem;
  margin-left: 380px;
  transition: all .3s ease-in-out;
}

#contants-container #page {
  margin-left: 2rem;
}


.grid {
  overflow: hidden;
}

.recopick-logo {
  float: left;
}

.update-date {
  float: right;
  margin: 5px;
}
</style>
