<template>
    <aside id="sideBar">
        <router-link to="/">Recopick 이용 가이드</router-link><br>
        <ul>
            <li><a @click="toViewPage('4718816')">Recopick 설치 가이드 (new)</a></li>
            <div id="guide">
                <ul>
                    <li><a @click="toViewPage('491543')">독립형 쇼핑몰인 경우 (자체 제작 쇼핑몰)</a></li>
                    <li><a @click="toViewPage('491533')">임대형 쇼핑몰인 경우(카페24, 메이크샵 등)</a></li>
                    <div id="lease">
                        <ul>
                            <li><a @click="toViewPage('3244203')">카페24</a></li>
                            <li><a @click="toViewPage('3244221')">카페24_모바일</a></li>
                            <li><a @click="toViewPage('3244217')">메이크샵</a></li>
                            <li><a @click="toViewPage('3244223')">메이크샵_모바일</a></li>
                            <li><a @click="toViewPage('3244210')">고도몰</a></li>
                            <li><a @click="toViewPage('3244225')">고도몰_모바일</a></li>
                            <li><a @click="toViewPage('3244228')">위사</a></li>
                            <li><a @click="toViewPage('3244230')">위사_모바일</a></li>
                            <li><a @click="toDisplayDocs('Shopby')">Shopby</a></li>
                        </ul>
                    </div>
                </ul>
            </div>
            <li><a @click="toViewPage('4718688')">페이지별 Recopick 추천 적용</a></li>
            <li><a @click="toViewPage('4718691')">Recopick 추천 위젯 이용 안내</a></li>
            <li><a @click="toDisplayDocs('APIs')">APls</a></li>
            <div id="apis">
                <ul>
                    <li><a @click="toDisplayDocs('2.1REST+APIs')">2.1REST APIs</a></li>
                    <div id="restApi">
                        <ul>
                            <li><a @click="toViewPage('3244497')">로그수집</a></li>
                            <div id="logs">
                                <ul>
                                    <li><a @click="toViewPage('3735912')">1) View 로그 (상품조회)</a></li>
                                    <li><a @click="toViewPage('3735921')">2) Basket 로그 (장바구니)</a></li>
                                    <li><a @click="toViewPage('3735916')">3) Order 로그 (구매완료)</a></li>
                                    <li><a @click="toViewPage('3735931')">4) Search 로그 (검색)</a></li>
                                    <li><a @click="toViewPage('3735923')">5) Visit 로그 (기타)</a></li>
                                    <li><a @click="toViewPage('3735929')">6) Like 로그 (좋아요)</a></li>
                                    <li><a @click="toViewPage('491684')">7) 추천상품 클릭 로그</a></li>
                                    <li><a @click="toViewPage('3244525')">8) 사용자 행동로그 확인</a></li>
                                </ul>
                            </div>
                            <li><a @click="toViewPage('3244098')">추천 API</a></li>
                            <div id="recos">
                                <ul>
                                    <li><a @click="toViewPage('3244404')">1) 함께본상품 / 함께구매한상품 추천</a></li>
                                    <li><a @click="toViewPage('3244364')">2) 실시간 개인화 추천</a></li>
                                    <li><a @click="toViewPage('3244452')">3) 통계형 상품 추천</a></li>
                                    <li><a @click="toViewPage('4358801')">4) 성/연령/카테고리별 상품 추천</a></li>
                                    <li><a @click="toViewPage('7536790')">5) 검색어 기반 상품 추천</a></li>
                                    <li><a @click="toViewPage('10256448')">6) 배송비 절약 상품추천</a></li>
                                </ul>
                            </div>
                            <li><a @click="toViewPage('3244499')">상품 API</a></li>
                            <div id="merchandises">
                                <ul>
                                    <li><a @click="toViewPage('491668')">1) 상품 메타정보 조회</a></li>
                                    <li><a @click="toViewPage('491671')">2) 상품 품절 처리 (추천 제외)</a></li>
                                    <li><a @click="toViewPage('491681')">3) 상품 품절 해제 (추천 재등록)</a></li>
                                </ul>
                            </div>
                            <li><a @click="toViewPage('1671181')">통계 API</a></li>
                            <div id="statistics">
                                <ul>
                                    <li><a @click="toViewPage('2261025')">01) PV, UV 통계 (일간)</a></li>
                                    <li><a @click="toViewPage('2261032')">02) PV, UV 통계 (주간)</a></li>
                                    <li><a @click="toViewPage('2261034')">03) PV, UV 통계 (월간)</a></li>
                                    <li><a @click="toViewPage('2261036')">04) 사용자 행동 로그 통계 (일간)</a></li>
                                    <li><a @click="toViewPage('2981917')">05) 사용자 행동 로그 통계 (주간)</a></li>
                                    <li><a @click="toViewPage('3244037')">06) 사용자 행동 로그 통계 (월간)</a></li>
                                    <li><a @click="toViewPage('3244042')">07) 추천 서비스 전체 통계 (일간)</a></li>
                                    <li><a @click="toViewPage('3244044')">08) 추천 서비스 전체 통계 (주간)</a></li>
                                    <li><a @click="toViewPage('3244046')">09) 추천 서비스 전체 통계 (월간)</a></li>
                                    <li><a @click="toViewPage('3244049')">10) 특정상품 추천클릭률 (일간)</a></li>
                                    <li><a @click="toViewPage('3244051')">11) 특정상품 추천클릭률 (주간)</a></li>
                                    <li><a @click="toViewPage('3244053')">12) 특정상품 추천클릭률 (월간)</a></li>
                                    <li><a @click="toViewPage('3244068')">13) 인당 평균 구매상품수 (일간)</a></li>
                                    <li><a @click="toViewPage('3244070')">14) 인당 평균 구매상품수 (주간)</a></li>
                                    <li><a @click="toViewPage('3244072')">15) 인당 평균 구매상품수 (월간)</a></li>
                                    <li><a @click="toViewPage('3244082')">16) 상품상세페이지 인당 평균 PV, UV (일간)</a></li>
                                    <li><a @click="toViewPage('3244084')">17) 상품상세페이지 인당 평균 PV, UV (주간)</a></li>
                                    <li><a @click="toViewPage('3244086')">18) 상품상세페이지 인당 평균 PV, UV (월간)</a></li>
                                    <li><a @click="toViewPage('23953426')">19) 추천 기여 매출 통계 (일간)</a></li>
                                    <li><a @click="toViewPage('23953430')">20) 추천 기여 매출 통계 (주간)</a></li>
                                    <li><a @click="toViewPage('23953433')">21) 추천 기여 매출 통계 (월간)</a></li>
                                </ul>
                            </div>
                            <li><a @click="toDisplayDocs('Error+response')">Error responses</a></li>
                        </ul>
                </div>
                    <li><a @click="toDisplayDocs('Client+Library+APIs')">Client Library APIs</a></li>
                    <div id="clientLib">
                        <ul>
                            <li><a @click="toDisplayDocs('AB+test+API')">AB test API</a></li>
                        </ul>
                    </div>
                    <li><a @click="toViewPage('4358163')">REST API를 이용한 A/B 테스트</a></li>
                    <li><a @click="toViewPage('6553731')">Hybrid app에서 ADID(gaid,idfa)획득방법</a></li>
                    <div id="hybrid">
                        <ul>
                            <li><a @click="toViewPage('11010062')">[참고] iOS에서 WebView 로 token 값 전달 하는 방법</a></li>
                        </ul>
                    </div>
                </ul>
            </div>
            <li><a @click="toViewPage('3244277')">자주하는 질문</a></li>
            <li><a @click="toViewPage('5996992')">Admin Dashboard 활용 안내</a></li>
        </ul>
    </aside>
</template>

<script>
    export default {
        name: "Sidebar",
        methods: {
            toViewPage(pageId) {
                this.$router.push({path: '/pages/viewpage.action', query: {pageId : pageId}});
            },
            toDisplayDocs(pageId){
                this.$router.push({path: `/display/docs/${pageId}`});
            },
        }
    }
</script>

<style>
    #sideBar {
        font-size: 14px;
        padding-top: 4rem;
        padding-bottom: 4rem;
        margin-left: 1rem;
        width: 100%;
        height: auto;
        white-space: nowrap;
    }

    #sideBar a {
        color: #2c3e50;
        cursor: pointer;
    }

    #sideBar a.router-link-exact-active {
        color: #0049B0;
    }

    #sideBar li {
      margin: 5px 0;
      list-style: disc;
    }

    #sideBar ul {
      padding-left: 18px;
    }

</style>